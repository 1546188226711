import React, { useState, useEffect, memo } from "react";
import { AppBar, Toolbar, Button, IconButton } from "@material-ui/core";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/Home";
import reactLocalStorage from "utils/localStorage";
import * as S from "./style";

import Logo from "assets/images/logo.png";

interface Props {
  handleDrawerOpen?: () => void;
  langSelected?: (item: any) => void;
  isOpen?: boolean;
  listOptions?: any;
}

function Header(props: Props) {
  const { handleDrawerOpen, isOpen, listOptions, langSelected } = props;
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [langActive, setLangActive] = useState(listOptions[0]);

  const onHandleClick = (item) => {
    setLangActive(item);
    langSelected(item);
    setActiveDropdown(false);
  };

  useEffect(() => {
    let lang = JSON.parse(
      reactLocalStorage.get("lang", JSON.stringify(langActive))
    );
    setLangActive(lang);
  }, []);

  return (
    <S.Header>
      <AppBar position="fixed" className="app-bar">
        <Toolbar className="navbar-dashboard">
          <div className="left-navbar">
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={`btn-action-sidebar ${
                isOpen === true ? "btn-open" : "btn-close"
              }`}
            >
              {isOpen === true ? (
                <FormatListBulletedIcon className="arrow" />
              ) : (
                <MoreVertIcon className="arrow" />
              )}
            </IconButton>
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </div>

          <div className="right-navbar">
            <Button
              variant="contained"
              color="primary"
              className="icon-heading"
            >
              <a
                href={import.meta.env.VITE_HOME_URL}
                target="_blank"
                rel="noreferrer"
              >
                <HomeIcon />
              </a>
            </Button>
            <S.Languages>
              <div className="language">
                <div
                  className="language-block"
                  onClick={() => setActiveDropdown(!activeDropdown)}
                >
                  <img src={langActive.src} alt="language" />
                </div>
                {activeDropdown ? (
                  <ul className="pop-content">
                    <li
                      className="close-btn"
                      onClick={() => setActiveDropdown(!activeDropdown)}
                    >
                      <CloseIcon />
                    </li>

                    {listOptions.map((item, index) => {
                      return (
                        <li key={index}>
                          <button onClick={() => onHandleClick(item)}>
                            <img src={item.src} alt={item.name} />
                            <span>{item.name}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </S.Languages>
          </div>
        </Toolbar>
      </AppBar>
    </S.Header>
  );
}

export default memo(Header);
