import styled from 'styled-components'

export const Container = styled.div`
    .rti--tag{
        background: #eaeaea;
        padding: 5px 10px 5px 10px;
        border-radius: 4px;
        margin: 0 10px 10px 0;
        button{
            margin-left: 10px;
        }
    }
    .rti--input{
        height: 34px;
        line-height: 34px;
        padding: 0 10px;
        border-radius: 4px;
        border: 1px solid rgba(0,0,0,0.42);
        flex: 1 1 100%;
    }
`


