import styled from 'styled-components'
import BgSideBar from 'assets/images/sidebar-2.jpg';

export const Sidebar = styled.div`
.sidebar{
  width: 280px;
  opacity: 1 !important;
  transition: all 0.3s ease-in-out;
  .logo-icon{
    margin-right: 7px;
    margin-left: 7px;
    width: 34px;
  }
  > .MuiDrawer-paper{
    width: 280px;
    overflow: hidden;
    transform: none !important;
    visibility: visible !important;
    border: none;
    /* box-shadow: 0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%); */
    transition: all 0.3s !important;
    z-index: 8 !important;
    top: 61px !important;
    /* border-right: 1px rgba(0, 0, 0, 0.12) solid; */
    box-shadow: 0 4px 5px -2px rgb(0 0 0 / 20%), 0 7px 10px 1px rgb(0 0 0 / 14%), 0 2px 16px 1px rgb(0 0 0 / 12%);
    &:before,
    &:after{
      top: 0;
      width: 280px;
      height: 100%;
      content: "";
      display: block;
      z-index: 3;
      position: absolute;
      left: 0;
    }
    &:before{
      /* background-image: url(${BgSideBar}); */
      background: #ffffff;
      background-size: cover;
      background-position: center center;
      z-index: 1;
    }
    /* &:after{
      opacity: .5;
      background: #000;
    } */
  }

  .sidebar-scroll{
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 70px);
    &> .scrollbar-container{
      /* padding: 0 15px; */
      scroll-behavior: smooth;
    }
  }

  .sidebar-content{
    z-index: 9;
    height: 100%;
  }

  .user-info{
    padding: 20px 15px;
  }
  .user-info-detail{
    p{
      margin: 0;
      font-size: 13px;
    }
  }
  .user-info-name{
    color: #222222;
  }
  .user-info-role{
    color: #6b778c;
    text-transform: capitalize;
  }

  .sub-info {
    display: block;
    align-items: center;
    color: #6b778c;
    font-weight: 500;
    /* margin-top: 10px; */
    height: 48px;
    align-items: center;
    padding: 14px 10px;
    border-radius: 16px;
    span{
      font-weight: bold;
    }
    &.active {
      box-shadow: none;
      background-color: rgba(86, 100, 210, 0.04);
      outline: none;
    }
    &:hover {
      outline: none;
      box-shadow: none;
      background-color: rgba(86, 100, 210, 0.04);
    }
    .code-name {
      text-align: center;
      font-size: 14px;
      width: 30px;
      margin-right: 15px;
      float: left;
      line-height: 1.5em;
    }
    .sub-info-item{
      margin: 0;
      line-height: 1.5em;
    }
  }

  .user-info-top{
    display: flex;
    align-items: center;
    position: relative;
    background: #f4f5f7;
    border-radius: 16px;
    display: flex;
    overflow: hidden;
    padding: 10px 8px;
    .avatar{
      height: 34px;
      width: 34px;
      border-radius: 50%;
      margin-right: 11px;
    }
    .icon-arrow {
      color: #ffffff;
      position: absolute;
      right: 10px;
      top: 0;
      height: 34px;
      line-height: 34px;
    }
    span{
      font-size: 14px;
      color: #ffffff;
    }
  }
  .sidebar-head{
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid hsla(0,0%,100%,.3);
    margin: 0 15px;
    .logo{
      display: inline-block;
      img{
        height: 30px;
      }
    }
  }
  .menu-list{
    padding: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    .title-list{
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 20px 0;
      margin: 0;
    }
    .list-item{
      padding: 0;
      margin: 5px 0;
      flex-direction: column;
      min-height: 50px;
      border-radius: 16px;
      /* &:first-child{
        .navigate-menu{
          box-shadow: 0 12px 20px -10px rgb(86 100 210 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(86 100 210 / 20%) !important;
          background-color: #5664d2 !important;
        }
      } */
      .MuiTouchRipple-root{
        display: none;
      }
      .navigate-menu {
        display: flex;
        text-decoration: none;
        font-size: 18px;
        align-items: center;
        font-weight: bold;
        color: #6b778c;
        border-radius: 16px;
        span{
          font-weight: bold;
        }
        svg{
          color: #6b778c;
        }
        &:hover{
          color: #6b778c;
        }
      }
      >.navigate-menu:hover {
        background-color: rgba(86, 100, 210, 0.04);
      }
      &.active{
        >.navigate-menu{
          background-color: rgba(86, 100, 210, 0.04);
          /* box-shadow: 0 12px 20px -10px rgb(86 100 210 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(86 100 210 / 20%) !important; */
          /* background-color: #5664d2 !important; */
          color: #263238;
          svg{
            color: #263238;
          }
        }
      }
      &:hover{
        background-color: transparent !important;
      }
    }
    .navigate-menu{
      padding: 10px 30px 10px 15px;
      width: 100%;
      border-radius: 3px;
      display: flex;
      align-items: center;
      position: relative;
      .MuiListItemIcon-root{
        min-width: auto;
        margin-right: 15px;
      }
      .arrow{
        position: absolute;
        top: 0;
        right: 20px;
        height: 50px;
      }
      &:hover{
        background-color: rgba(200, 200, 200, 0.2);
        color: #ffffff;
      }
    }
    .list-subMenu{
      width: 100%;
      .submenu{
        display: flex;
        align-items: center;
      }
    }
  }

  .investors{
    margin-top: 20px;
    /* padding-bottom: 20px;
    border-bottom: 1px solid hsla(0,0%,100%,.3); */
  }

  .hiden-on-close{
    position: relative;
    white-space: nowrap;
    transform: translate3d(0px, 0, 0);
    transition: all 0.3s ease-in-out;
    span{
      white-space: nowrap;
    }
  }

  .list-on-close{
    transition: all 0.3s ease-in-out;
  }
  
  &.close{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    text-shadow: none;
    &:not(:hover){
      width: 82px; 
      > .MuiDrawer-paper{
        width: 82px;
      } 
      .hiden-on-close{
        opacity: 0;
        transform: translate3d(-25px, 0, 0);
        visibility: hidden;
      }
      .list-on-close{
        padding: 0 !important;
      }
    }
  }
}
`