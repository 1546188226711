import { useState, useEffect, memo } from "react";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import StorefrontIcon from "@material-ui/icons/Storefront";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AddIcon from "@material-ui/icons/Add";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import Avatar from "assets/images/faces/avatar.jpg";
import FolderIcon from "@material-ui/icons/Folder";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import * as S from "./style";
import { SideBar, Header } from "components";
import { useTranslation } from "react-i18next";
import Routes from "constants/routes";
import Kr from "assets/images/flags/KR.png";
import En from "assets/images/flags/GB.png";
import reactLocalStorage from "utils/localStorage";

interface Props {
  boardContent?: any;
  getInfoWallet?: () => void;
}

function DefaultLayout(props: Props) {
  const { boardContent } = props;
  const { t } = useTranslation("common");
  const { i18n } = useTranslation("common");
  const [open, setOpen] = useState(true);
  const listOptions = [
    {
      src: Kr,
      name: "Korea",
      lang: "kr",
    },
    {
      src: En,
      name: "English",
      lang: "en",
    },
  ];

  const userInfo = {
    avtSrc: Avatar,
    useName: "Tania Andrew",
    subInfo: [
      {
        id: 1,
        codeName: "L",
        pageName: "로그아웃",
        link: "#",
        isActive: false,
      },
    ],
  };

  const dataSidebar = [
    {
      titleMenu: "통계",
      icon: <DashboardIcon />,
      link: Routes.ADMIN_DASHBOARD,
      subMenu: null,
    },
    {
      titleMenu: "회원관리",
      icon: <PeopleAltIcon />,
      link: Routes.ADMIN_MEMBER_LIST,
      subMenu: [
        {
          titleMenu: "회원 리스트",
          icon: <AddIcon />,
          link: Routes.ADMIN_MEMBER_LIST,
          subMenu: null,
        },
        {
          titleMenu: "탈퇴회원 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_MEMBER_REMOVE_MANAGE,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: "청약 증권 관리 (신한)",
      icon: <LooksOneIcon />,
      link: Routes.ADMIN_PRODUCT_MANAGE_V3_2,
      subMenu: [
        {
          titleMenu: "응모상품 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_PRODUCT_MANAGE_V3_2,
          subMenu: null,
        },
        {
          titleMenu: "주문서 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V3_2,
          subMenu: null,
        },
        {
          titleMenu: "소유지분 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_OWNERSHIP_STATUS_V3_2,
          subMenu: null,
        },
        {
          titleMenu: "매각관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_SALE_MANAGEMENT_V3_2,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: "청약 증권 관리 (헥토)",
      icon: <LooksTwoIcon />,
      link: Routes.ADMIN_PRODUCT_MANAGE_V3,
      subMenu: [
        {
          titleMenu: "응모상품 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_PRODUCT_MANAGE_V3,
          subMenu: null,
        },
        {
          titleMenu: "주문서 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V3_1,
          subMenu: null,
        },
        {
          titleMenu: "소유지분 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_OWNERSHIP_STATUS_V3,
          subMenu: null,
        },
        {
          titleMenu: `${t("SALE_MANAGEMENT_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_SALE_MANAGEMENT_V3,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: "공동구매관리",
      icon: <Looks3Icon />,
      link: Routes.ADMIN_PRODUCT_MANAGE,
      subMenu: [
        {
          titleMenu: "상품 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_PRODUCT_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: "주문서 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_ORDER_HISTORY,
          subMenu: null,
        },
        {
          titleMenu: "대기주문서 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_WAITING_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: "소유지분 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_OWNERSHIP_STATUS,
          subMenu: null,
        },
        {
          titleMenu: "매각관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_SALE_MANAGEMENT,
          subMenu: null,
        },
      ],
    },
    // {
    //   titleMenu: `${t("MARKETING_EVENT_HEADING")}`,
    //   icon: <EventIcon />,
    //   link: Routes.ADMIN_EVENT_PRODUCT_MANAGE,
    //   subMenu: [
    //     {
    //       titleMenu: `${t("EVENT_PRODUCT_MANAGE_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_EVENT_PRODUCT_MANAGE,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: `${t("EVENT_SUBSCRIBER_MANAGE_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_EVENT_SUBSCRIBER_MANAGE,
    //       subMenu: null,
    //     },
    //   ],
    // },
    {
      titleMenu: "자료 관리",
      icon: <FolderIcon />,
      link: Routes.ADMIN_GP_CALENDAR,
      subMenu: [
        {
          titleMenu: "청약 캘린더",
          icon: <AddIcon />,
          link: Routes.ADMIN_GP_CALENDAR,
          subMenu: null,
        },
        {
          titleMenu: "팝업관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_POPUP_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: "Top 10 Artists 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_TOP_ARTIST,
          subMenu: null,
        },
        {
          titleMenu: "배너 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_BANNER_MANAGE,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: "마켓인사이드",
      icon: <EmojiObjectsIcon />,
      link: Routes.ADMIN_NOTICE,
      subMenu: [
        {
          titleMenu: "뉴스레터",
          icon: <AddIcon />,
          link: Routes.ADMIN_NEWSLETTER,
          subMenu: null,
        },
        {
          titleMenu: "Research",
          icon: <AddIcon />,
          link: Routes.ADMIN_TEMP,
          subMenu: null,
        },
        {
          titleMenu: "아트인사이드",
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_INSIGHT,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: "고객센터",
      icon: <HeadsetMicIcon />,
      link: Routes.ADMIN_NOTICE,
      subMenu: [
        {
          titleMenu: "공시",
          icon: <AddIcon />,
          link: Routes.ADMIN_GONGCHI,
          subMenu: null,
        },
        {
          titleMenu: "공지사항",
          icon: <AddIcon />,
          link: Routes.ADMIN_NOTICE,
          subMenu: null,
        },
        {
          titleMenu: "이용가이드",
          icon: <AddIcon />,
          link: Routes.ADMIN_USER_GUIDE,
          subMenu: null,
        },
        {
          titleMenu: "자주묻는질문",
          icon: <AddIcon />,
          link: Routes.ADMIN_FAQ,
          subMenu: null,
        },
        {
          titleMenu: "1:1문의사항",
          icon: <AddIcon />,
          link: Routes.ADMIN_INQUIRY,
          subMenu: null,
        },
        // {
        //   titleMenu: "취화담 관리",
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_GALLERY_MANAGE,
        //   subMenu: null,
        // },
        {
          titleMenu: "약관",
          icon: <AddIcon />,
          link: Routes.ADMIN_TERMS,
          subMenu: null,
        },
      ],
    },
    // {
    //   titleMenu: `${t("EVENT_HEADING")}`,
    //   icon: <RedeemIcon />,
    //   link: Routes.ADMIN_EVENT,
    //   subMenu: [
    //     {
    //       titleMenu: `${t("PRAP_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_EVENT,
    //       subMenu: null,
    //     },
    //   ],
    // },
    // {
    //   titleMenu: `${t("PUSH_NOTIFICATION_HEADING")}`,
    //   icon: <AccessAlarmIcon />,
    //   link: Routes.ADMIN_PUSH_NOTIFICATION,
    //   subMenu: null,
    // },
    {
      titleMenu: "AnG Store 관리",
      icon: <StorefrontIcon />,
      link: Routes.ADMIN_ART_PRODUCT_MANAGE,
      subMenu: [
        {
          titleMenu: "응모상품 관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_PRODUCT_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: "대기 주문서",
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_ORDER_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: "재입고요청관리",
          icon: <AddIcon />,
          link: Routes.ADMIN_RESTOCK_REQUEST_MANAGE,
          subMenu: null,
        },
      ],
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getLangSelected = (e) => {
    i18n.changeLanguage(e.lang);
    reactLocalStorage.set("lang", JSON.stringify(e));
  };

  useEffect(() => {
    let langSelected = JSON.parse(
      reactLocalStorage.get("lang", JSON.stringify(listOptions[0]))
    ).lang;
    i18n.changeLanguage(langSelected);
  }, []);

  return (
    <S.Wrapper>
      <Header
        handleDrawerOpen={handleDrawerOpen}
        langSelected={getLangSelected}
        isOpen={open}
        listOptions={listOptions}
      />
      <S.Body>
        <SideBar userInfo={userInfo} sideBarMenu={dataSidebar} open={open} />
        <main className="main-content overflow-off">
          <div className="board-content">{boardContent}</div>
        </main>
      </S.Body>
    </S.Wrapper>
  );
}

export default memo(DefaultLayout);
