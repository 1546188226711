import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../redux/store'

import { NewUsers, ActionResponse, NewUsersState } from 'models/newUsers'
import { getNewRegistrations as newUsersService, whiteListAnEmail, rejectAnEmail, filterUser } from 'services/newRegistrations/index'

const newUsersInitialState: NewUsersState = {
  isLoading: false,
  data: {
    msgCode: 'Failed',
    data: {
      newUsers: [],
      currentPage: 1,
      totalPages: 1,
      limit: 0,
      count: 0
    },
    errors: {}
  },
  msgCode: '',
  success: false
}

function startLoading(state: NewUsersState) {
  state.isLoading = true
}

function loadingFailed(state: NewUsersState | undefined, action: PayloadAction<any>) {
  state.isLoading = false
  // state.data.errors = action.payload?.error
}

function reset(state: NewUsersState) {
  state.data = null
  state.isLoading = false
}

const newUsers = createSlice({
  name: 'newUsers',
  initialState: newUsersInitialState,
  reducers: {
    begin: startLoading,
    setNewUsers(state, { payload }: PayloadAction<NewUsers>) {
      const { data } = payload
      state.data = {
        data: {
          newUsers: data['newUsers'],
          currentPage: data.currentPage,
          totalPages: data.totalPages,
          limit: data.limit,
          count: data.count
        },
      }
    },
    filterNewUsers(state, { payload }: PayloadAction<NewUsers>) {
      const { data } = payload
      state.data = {
        data: {
          newUsers: data['newUsers']
        }
      }
    },
    whiteList(state, {payload}: PayloadAction<ActionResponse>) {
      const { msgCode, success } = payload
      state.msgCode = msgCode
      state.success = success
    },
    reject(state, {payload}: PayloadAction<ActionResponse>) {
      const { msgCode, success } = payload
      state.msgCode = msgCode
      state.success = success
    },
    failure: loadingFailed,
    resetState: reset
  }
})

export const {
  setNewUsers,
  filterNewUsers,
  whiteList,
  reject,
  begin,
  failure,
  resetState
} = newUsers.actions

export const getNewUsers = ({page, eq}): AppThunk => async (dispatch) => {
  try {
    dispatch(begin())
    const response = await newUsersService({page, eq})
    dispatch(setNewUsers(response))
  } catch(error) {
    dispatch(failure(error))
  }
}

export const whiteListUserEmail = ({email, eventID}): AppThunk => async (dispatch) => {
  try {
    dispatch(begin())
    const response = await whiteListAnEmail({email, eventID})
    dispatch(whiteList(response))
  } catch(error) {
    dispatch(failure(error))
  }
}

export const rejectUserEmail = ({email, eventID}): AppThunk => async (dispatch) => {
  try {
    dispatch(begin())
    const response = await rejectAnEmail({email, eventID})
    dispatch(reject(response))
  } catch(error) {
    dispatch(failure(error))
  }
}

export const filterUsers = (formData: any): AppThunk => async (dispatch) => {
  try {
    dispatch(begin())
    const response = await filterUser(formData)
    dispatch(filterNewUsers(response))
  } catch(error) {
    dispatch(failure(error))
  }
}

export default newUsers.reducer