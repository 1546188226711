import styled from 'styled-components'

export const Container = styled.div`
    .recomended-size{
        margin-bottom: 20px;
    }
    button{
        border: none;
    }
    .list-render-img{
        display: flex;
        margin: 0 -10px;
        flex-wrap: wrap;
        .add-img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
        .image-item{
            width: 150px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            flex: 0 0 150px;
            position: relative;
            overflow: hidden;
            .image-item-wrap{
                border-radius: 10px;
                overflow: hidden;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img{
                max-width: 100%;
                height: auto;
                display: block;
                /* &.main{
                    border: 10px red solid;
                } */
            }
        }
    }
    .image-item__btn-wrapper{
        display: flex;
        gap: 10px;
        button{
            border-radius: 4px;
        }
        .btn-update, .btn-remove, .main-img{
            position: absolute;
        }
        .btn-remove{
            top: 0;
            right: 0;
        }

        .btn-update{
            top: 0;
            right: 50px;
        }
        .main-img{
            bottom: 0;
            right: 0;
            cursor: default;
            left: 0;
            width: calc(100% - 20px);
            cursor: default;
            margin: auto;
        }
    }
    .remove-all{
        border-radius: 4px;
        height: 30px;
        padding: 0 10px;

    }
`


