import { createGlobalStyle } from "styled-components";
import { Themes } from "configs/theme";

const GlobalStyle = createGlobalStyle`

  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body {
    /* overflow: hidden; */
    background-color: #F9FAFC !important;
    font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    height: 100%;
  }

  a{
    &:hover{
      text-decoration: none;
    }
  }

  p,
  label {
    font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5em;
  }
  
  .image {
    max-width: 100%;
    object-fit: cover;
  }

  img{
    /* display: block; */
  }
  
  .ant-form-item-children-icon {
    top: 50% !important;
  }
  .login__logo-block {
    //es
    background-color: ${Themes["default"].containerBg};
  }
  .ant-menu-vertical .ant-menu-item {
    margin: 0 auto;
  }
  .ant-menu-inline .ant-menu-item {
    margin: 0 auto;
  }
  .anticon {
    vertical-align: 0.2em !important;
  }
  tr.ant-table-expanded-row > td {
    background: ${Themes["default"].mainWhite};
  }
  .white-list__modal-title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
  .input__label {
    color: ${Themes["default"].inputLabel};
  }
  .ant-layout-sider {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }
  .ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }

  .icon-search{
    margin:  0 10px;
  }

  .date-filter-option{
    min-width: 200px !important;
    height: 34px !important;
  }

  .custom-pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-pagination{
      background-color: transparent !important;
      height: 36px;
      width: 36px;
      min-width: 36px;
      margin: 5px;
      padding: 0;
      border-radius: 100%;
      &.disable{
        color: #999999;
        cursor: default;
      }
      &.enable{
        color: #222222;
      }
      &:hover{
        background-color: rgba(86,100,210,0.04) !important;
      }
    }
  }
  
  .mg-bt-20{
    margin-bottom: 20px;
  }
  .block-title{
    padding: 24px;
    color: #222222;
    font-weight: bold;
    border-bottom: 1px rgba(0,0,0,0.12) solid;
    margin: 0;
    font-size: 20px;
  }
  .ql-toolbar{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .ql-container{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ql-editor{
    min-height: 300px;
  }
  .input-table{
    border: 1px red solid;
    /* height: 36px; */
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.42);
    display: flex;
    align-items: center;
    min-width: 200px;
    &.input-format{
      padding: 10px;
      height: 36px;
    }
    .MuiFormHelperText-root{
      width: 100%;
    }
    &.currency{
      .MuiOutlinedInput-adornedStart{
        padding-left: 0;
      }
      .MuiInputAdornment-root{
        display: none;
      }
      /* input{
        min-height: 36px;
      } */
    }
    input{
      padding: 10px;
      height: 100%;
    }
    textarea{
      padding: 0 10px;
      height: 100%;
    }
    &.txt-area{
      height: 100%;
    }
  }
  .select-form{
    min-width: 200px;
    &.w-150{
      min-width: 150px;
    }
  }
  .flex-row{
    display: flex;
    align-items: center;
  }
  .label-table{
    margin-left: 10px;
  }
  .check-box-custom{
    margin: 0 30px 0 0 !important;
    > span{
      &:hover{
        background-color: rgba(86,100,210,0.04);
      }
    }
  }
  .label-btn{
    cursor: pointer;
    font-weight: bold;
    white-space: nowrap;
  }
  .btn-edit{
    color: #5664d2;
  } 
  .btn-delete{
    color: #d14343;
  }
  .success{
    color: #1e7e34 !important;
  }
  .warning{
    color: #d14343 !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
    transition: background-color 5000s ease-in-out 0s;
  }
  .img-heading{
    object-fit: cover;
    width: 100%;
  }
  .mb-20{
    margin-bottom: 20px;
  }
  .no-mg{
    margin: 0 !important;
  }
  .mw-30{
    min-width: 30%;
  }
  .selelct-data{
    min-width: 300px;
  }
  .table-note{
    margin: 10px 0;
  }
  .table-note-title{
    margin: 10px 0;
    font-size: 16px;
    font-weight: bold;
  }
  .image-uploaded{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 10px;
    .image-item{
      width: 150px;
      height: 150px;
      flex: 0 0 150px;
      padding: 10px;
      .image-wrap{
        border: 1px #eaeaea solid;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      img{
        max-width: 100%;
        height: auto;
      }
    }
  }
  .label-old-img{
    margin: 10px 0 0;
  }
  .autocomplete-style > div{
    padding: 0 65px 0 9px !important;
  }
  .btn-confirm{
    padding: 10px 20px !important;
    box-shadow: 0 12px 20px -10px rgb(86 100 210 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(86 100 210 / 20%) !important;
    background-color: #5664d2 !important;
    color: #ffffff !important;
    height: 36px;
    min-width: 100px;
  }
  .btn-delete-product{
    padding: 10px 20px !important;
    background-color: #d14343 !important;
    box-shadow: 0 12px 20px -10px rgb(209 67 67 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(209 67 67 / 20%) !important;
    color: #ffffff !important;
    height: 36px;
    min-width: 100px;
  }
  .label-btn-change{
    padding: 3px 10px;
    border-radius: 3px;
    cursor: pointer;
    &.success{
      background: #1e7e34;
      color: #ffffff !important;
    }
    &.warning{
      background: #d14343;
      color: #ffffff !important;
    }
    &.alert{
      background: #E2A76F;
      color: #ffffff !important;
    }
  }
  .custom-tab {
    .MuiTabs-indicator{
      display: none;
    }
    .MuiTabs-flexContainer{
      /* justify-content: center; */
      button{
        /* border: 1px #eaeaea solid; */
        margin-right: 10px;
        background: #eaeaea;
        &.Mui-selected{
          background: #222222;
          color: #ffffff;
        }
      }
    }
  }

  .attachment-st{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    a{
      display: inline-block;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      > span{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background: transparent;
        opacity: 0;
        visible: hidden;
        transition: all 0.3s ;
      }
      &:hover{
        > span{
          background: rgba(0,0,0,0.5);
          opacity: 1;
          visible: visible;
        }
      }
    }
  }
  .txt-overflow{
    max-width: 250px;
    white-space: nowrap !important;
    text-overflow: ellipsis;
    display: block !important;
    &.not-focus{
      opacity: 0.3;
    }
  }

  .h-filter{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .order-filter-btn{
    cursor: pointer;
  }
  .order-filter{
    display: flex;
    /* flex-direction: column; */
  }
  .label-no-mg-bt{
    > label{
      margin-bottom: 0;
    }
  }
  .gs-grp-btn{
    display: flex;
    gap: 20px;
  }
  /* .select-form{
    .MuiSelect-select{
      font-size: 12px;
    }
  } */
  .owner-amount{
    margin-right: auto !important;
    font-size: 14px;
  }

  .hash-tag-item{
    background: #eaeaea;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    margin: 0 10px 10px 0;
    display: inline-block ;
  }

  .certificate{
    .recomended-size, .main-img{
      display: none;
    }
  }
  .tox-notifications-container, .tox-statusbar__branding, .tox-promotion{
    display: none !important;
  }
  .color-blue{
    color: blue !important;
    font-weight: bold !important;
  }
  .bg-warning{
    background-color: #fdcdcd !important;
  }
  .btn-warning{
    padding: 10px 20px !important;
    box-shadow: 0 12px 20px -10px rgb(86 100 210 / 28%),
      0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(86 100 210 / 20%) !important;
    background-color: red !important;
    color: #ffffff !important;
    height: 36px;
    white-space: nowrap;
  }
`;

export default GlobalStyle;
