import styled from 'styled-components'

export const CarBrand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &.active .btn-car{
    background: #5664d2;
    color: #ffffff;
  }
  .btn-car{
    margin: 5px;
    padding: 10px;
    width: 100%;
  }
`