import { createSlice } from "@reduxjs/toolkit";

export interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    openLoading: (state) => {
      state.isLoading = true;
    },
    closeLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { openLoading, closeLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
