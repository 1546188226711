import styled from "styled-components";

export const DateTimePicker = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px;
  max-width: 200px;
  &.active {
    border-color: red;
  }
  .icon-date-picker {
    width: 25px;
    height: 25px;
  }
  .react-datepicker-wrapper {
    z-index: 2;
  }
  .react-datepicker-popper {
    z-index: 3;
  }
  .react-datepicker__day-names {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .react-datepicker__input-container {
    input {
      border-radius: 5px;
      outline: none;
      font-size: 14px;
      border: none;
      background: transparent;
      cursor: pointer;
      height: 34px;
    }
  }
  .react-datepicker__header {
    background: #5664d2;
    .react-datepicker__current-month {
      color: #ffffff;
    }
    .react-datepicker__day-name {
      color: #ffffff;
    }
  }
  .react-datepicker__day {
    margin: 5px;
    &:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day--selected {
    background: #5664d2;
    border-radius: 50%;
  }
  .react-datepicker__week {
    font-size: 13px;
  }
  .react-datepicker__navigation--previous {
    left: 7px;
    border-right-color: #ffffff;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ffffff;
  }
  .react-datepicker {
    border: 1px solid #5664d2;
    position: relative;
    z-index: 3;
  }
  .react-datepicker__triangle {
    border-bottom-color: #5664d2 !important;
    left: 95px !important;
  }
`;
