const Endpoints = {
  LOGIN: "/auth/sign-in-new-admin",
  LOGIN_TWITTER: "/login-twitter",
  GET_ADMIN_DEPOSIT: "/api/users/page",
  WHITE_LIST_ACTION: "/api/email/whitelisted",
  REJECT_ACTION: "/api/email/rejected",
  GET_WHITELIST: "/api/whitelisted/unregistered",
  GET_NEW_WHITELIST: "/api/whitelisted/query",
  CREATE_WHITELIST_EDM: "/api/whitelisting",
  REJECT_FROM_WHITELIST: "/api/email/rejected/from/whitelisted",
  SEARCH_USER: "/api/user/search",

  AGENCY_LIST: "/inviter/gets",
  EDIT_AGENCY: "/wallet-pool/add-remain",
  CHECK_WALLET: "/wallet-pool/list-by-dev",
  TRANSFER_HISTORY: "/transaction-delegate/list-by-dev/transfer",

  DELETE_WALLET: "/wallet-pool/remove",
  WALLET_REMAIN: "/wallet-pool/add-remain",
  TRANSFER_COIN: "/transaction-delegate/send",
  TRANSACTION_LIST: "/transaction-delegate/list-by-dev",
  USER_BALANCE: "/balance",

  GET_ME: "/api/v1/me",

  GET_LOCAL_BRAND: "/api/v1/third-api/local-brand",
  GET_MODEL_LIST: "/api/v1/third-api/model-list",

  GET_STATISTIC_USER: "/api/v1/admin/statistic-user",
  GET_USED_POINT: "/api/v1/admin/detail-user/point-used",
  UPDATE_USER_STATUS: "/api/v1/admin/update-user-status",
  CHANGE_COMMENT_STATUS: "/api/v1/comments/update-status",
  CHANGE_CAR_TALK_STATUS: "/api/v1/cartalk/update-status",
  GET_ALL_COMMENTS: "/api/v1/comments",

  GET_ALL_CAR_INFO: "/api/v1/cartalk",
  GET_ALL_CAR_CATEGORY: "/api/v1/category-cartalk",

  GET_PAYMENT_HISTORY: "/api/v1/point-center/reward-history",
  GET_POINT_STATISTIC: "/api/v1/point-center/statistical-point",
  GET_POINT_CONFIG: "/api/v1/point-center/point-reward-config",

  GET_ALL_NOTIFICATION: "/api/v1/admin/acarz-notification",
  EDIT_NOTIFICATION: "/api/v1/admin/acarz-notification/update",

  GET_ALL_QNA: "/api/v1/admin/qnas",
  GET_QNA_CATEGORY: "/api/v1/admin/qnas/categories",
  ANSWER_QNA: "/api/v1/admin/qnas/answer",

  //New PJ
  //Dash Board
  GET_DASH_BOARD_DATA: "/statistic/dashboard",

  //Member Manage
  GET_ALL_MEMBER: "/user/list-user",
  GET_MEMBER_DETAIL: "/user/details",
  CHANGE_APPROVED: "/user/change-active",
  GET_POINT_HISTORY: "/user/point-history",
  ADD_POINT: "/user/add-point",
  CONFIRM_LEAVE: "/user/confirm-leave",
  UPDATE_MEMBER_NOTE: "/user/admin-update-user-info",

  //Group Purchase Manage
  GET_GROUP_PURCHASE: "/gp/admin-find",
  GET_GROUP_PURCHASE_DETAIL: "/gp/gp-detail-for-admin",
  UPDATE_GROUP_PURCHASE_DETAIL: "/gp/update-gp",
  UPDATE_GROUP_PURCHASE_DETAIL_DURING_START: "/gp/update-gp-during-start",
  UPDATE_GROUP_PURCHASE_DETAIL_AFTER_FINISH: "/gp/update-gp-after-finish",
  UPDATE_GROUP_PURCHASE_STATUS: "/gp/update-status-gp-after-finish",
  CREATE_GROUP_PURCHASE: "/gp/create-gp",
  DELETE_GROUP_PURCHASE: "/gp/del-gp",

  GET_ALL_ORDER: "/gp/find-ogp",
  UPDATE_ORDER: "/gp/update-order-basic",
  CONFIRM_ORDER: "/gp/confirm-order",
  CANCEL_ORDER: "/gp/cancel-order",
  REFUND_ORDER: "gp/req-cancel-order",
  REJECT_CANCELLATION: "gp/reject-req-cancel",

  SETTING_HOLDING_VOTE: "gp/setting-holding-vote",
  HOLDING_VOTE_STATISTIC: "gp/holding-vote-statistic",

  //Group Purchase Manage V2
  UPDATE_GROUP_PURCHASE_DETAIL_V2: "/gp-v2/update-gp",
  CREATE_GROUP_PURCHASE_V2: "/gp-v2/create-gp",
  DELETE_GROUP_PURCHASE_v2: "/gp-v2/del-gp",

  UPDATE_ORDER_V2: "/gp/gp-v2/update-order-basic",
  CONFIRM_ORDER_V2: "/gp/gp-v2/confirm-order",
  CANCEL_ORDER_V2: "/gp/gp-v2/cancel-order",
  REFUND_ORDER_V2: "gp/gp-v2/req-cancel-order",
  REJECT_CANCELLATION_V2: "gp/gp-v2/reject-req-cancel",

  //Group Purchase Manage V3
  UPDATE_GROUP_PURCHASE_DETAIL_V3: "/gp-v3/update-gp",
  CREATE_GROUP_PURCHASE_V3: "/gp-v3/create-gp",
  DELETE_GROUP_PURCHASE_v3: "/gp-v3/del-gp",

  UPDATE_ORDER_V3: "/gp/gp-v3/update-order-basic",
  CONFIRM_ORDER_V3: "/gp-v3/confirm-order",
  CANCEL_ORDER_V3: "/gp/gp-v3/cancel-order",
  REFUND_ORDER_V3: "gp/gp-v3/req-cancel-order",
  REJECT_CANCELLATION_V3: "gp/gp-v3/reject-req-cancel",

  CHECK_APPLY_ORDER_V3: "gp-v3/checking-apply-order-v3",
  SET_APPLY_ORDER_V3: "gp-v3/admin-apply-order-v3",
  SEND_AML_NOTICE: "gp-v3/send-aml-noti",
  UPDATE_DEPOSIT_PROOF: "gp-v3/update-deposit-proof",
  ADMIN_URGE_DEPOSIT: "gp-v3/admin-urge-deposit",
  TRANSFER_HISTORY_OWNER_PROCESS: "gp/ownership-manager/owner-transfer-process",

  //Ownership
  GET_OWNERSHIP_SERIAL: "/gp/ownership-manager/list-serial",
  GET_TRANFER_HISTORY: "/gp/ownership-manager/transfer-ownership-history",
  TRANSFER_OWNERSHIP: "/gp/ownership-manager/transfer-ownership-p2p",
  GET_COLLECT_USER_DATA: "/gp/ownership-manager/collects-user-data",
  CLEAR_USER_DATA: "/gp/ownership-manager/clear-collect-user-data",
  UPDATE_SPLIT_STATUS: "/gp/ownership-manager/update-dividends-status-gpcud",
  UPDATE_SPLIT_STATUS_ALL: "/gp/ownership-manager/update-dividends-status-all",

  CHANGE_OWNER: "/gp/transfer-ownership-gp",
  CONFIRM_ORDER_WAITING: "/gp/confirm-order-waiting",
  UPDATE_OWNERSHIP_DISTRIBUTE:
    "/gp/ownership-manager/update-ownership-distribution",

  // Art Product
  GET_ALL_ART_PRODUCT: "/market/products-admin",
  GET_ART_PRODUCT_DETAIL: "/market/product-details-admin",
  UPDATE_ART_PRODUCT: "/market/update-product",
  DELETE_ART_PRODUCT: "/market/del-product",
  CREATE_ART_PRODUCT: "/market/create-product",
  GET_ART_PRODUCT_CATEGORY: "/market/categories",

  GET_ALL_ART_ORDER: "/market/list-order",
  GET_ART_ORDER_DETAIL: "/market/order-details",
  UPDATE_ART_ORDER: "/market/update-order",
  CHANGE_STATUS_ORDER: "/market/change-status-order",

  GET_ALL_RESTOCK_REQUEST: "/market/restock-reqs",
  GET_RESTOCK_REQUEST_DETAIL: "/market/details-restock-req-product",
  DELETE_RESTOCK_REQUEST: "/market/del-restock-req",

  //Shipping
  GET_ALL_SHIPPING_COMPANY: "/shipping-company/get-all",
  CREATE_SHIPPING_COMPANY: "/shipping-company/create",
  UPDATE_SHIPPING_COMPANY: "/shipping-company/update",
  DELETE_SHIPPING_COMPANY: "/shipping-company/delete",
  SHIPPING_COMPANY_DETAIL: "/shipping-company/get-by-id",

  //Popup Manage
  CREATE_POPUP: "/popup/create",
  DELETE_POPUP: "/popup/delete",
  UPDATE_POPUP: "/popup/update",
  GET_ALL_POPUP: "/popup/admin-get-all",
  GET_POPUP_BY_ID: "/popup/get-by-id",

  //Top Artist
  CREATE_TOP_ARTIST: "/top-artist/create",
  DELETE_TOP_ARTIST: "/top-artist/delete",
  UPDATE_TOP_ARTIST: "/top-artist/update",
  GET_ALL_TOP_ARTIST: "/top-artist/get-all",
  GET_TOP_ARTIST_BY_ID: "/top-artist/get-by-id",
  GET_TOP_ARTIST_TITLE: "/top-artist/title",
  UPDATE_TOP_ARTIST_TITLE: "/top-artist/edit-title",

  //Article Manage
  GET_ALL_ARTICLE_CATEGORY: "/article/get-all-category",
  UPDATE_ARTICLE: "/article/edit",

  //Upload
  SINGLE_UPLOAD: "/upload/single-file",

  //FAQ
  CREATE_FAQ: "/faq/create",
  DELETE_FAQ: "/faq/delete",
  UPDATE_FAQ: "/faq/edit",
  GET_ALL_FAQ: "/faq/get-all",
  GET_FAQ_BY_ID: "/faq/get-by-id",
  GET_FAQ_CATEGORY: "/faq/get-all-category",

  //TERMS
  CREATE_TERMS: "/terms/create",
  DELETE_TERMS: "/terms/delete",
  UPDATE_TERMS: "/terms/edit",
  GET_ALL_TERMS: "/terms/get-all",
  GET_TERMS_BY_ID: "/terms/get-by-id",
  GET_TERMS_CATEGORY: "/terms/get-all-category",

  //Inquiry
  SEND_INQUIRY_ANSWER: "/qna/send-qna",
  DELETE_INQUIRY: "/qna/delete-qna",
  EDIT_INQUIRY_ANSWER: "/qna/edit-answer",
  GET_ALL_INQUIRY: "/qna/get-qna-list",
  GET_INQUIRY_BY_ID: "/qna/get-qna-detail",
  GET_INQUIRY_CATEGORY: "/qna/category-list",
  GET_INQUIRY_ATTACHMENT: "/upload/generate-presigned-url-s3",

  //Partnership
  DELETE_PARTNERSHIP: "/partnership/del",
  UPDATE_PARTNERSHIP: "/partnership/update",
  GET_ALL_PARTNERSHIP: "/partnership/find",

  //Newsletter
  CREATE_NEWSLETTER: "/article/create",
  DELETE_NEWSLETTER: "/article/delete",
  UPDATE_NEWSLETTER: "/article/edit",
  GET_ALL_NEWSLETTER: "/article/get-all",
  GET_NEWSLETTER_BY_ID: "/article/get-by-id",

  //Banner
  CREATE_BANNER: "/banner/create",
  DELETE_BANNER: "/banner/delete",
  UPDATE_BANNER: "/banner/update",
  GET_ALL_BANNER: "/banner/get-all",
  GET_BANNER_BY_ID: "/banner/get-by-id",

  //Cutomer Service
  USE_GUIDE_GET_ALL: "/faq/get-all",
  USE_GUIDE_DELETE: "/faq/delete",
  USE_GUIDE_UPDATE: "/faq/edit",
  USE_GUIDE_CREATE: "/faq/create",
  USE_GUIDE_DETAIL: "/faq/get-by-id",
  USE_GUIDE_GET: "/faq/get-user-guide",

  //Gallery Manage
  GET_GALLERY_LIST: "/esr/find-all",
  UPDATE_GALLERY: "/esr/admin-update",

  //Export
  EXPORT_USER: "/excel-export/export-user",
  EXPORT_GP: "/excel-export/export-gp",
  EXPORT_GP_ORDER: "/excel-export/export-ogp",
  EXPORT_GP_OWNERSHIP: "/excel-export/export-gp-complete",
  EXPORT_GP_OWNERSHIP_SERIAL: "/excel-export/export-gp-list-serial",
  EXPORT_ART_PRODUCT: "/excel-export/export-mk-product",
  EXPORT_ART_ORDER: "/excel-export/export-mk-order",
  EXPORT_ART_RESTOCK: "/excel-export/export-mk-restock-reqs",
  EXPORT_EVENT_PRAP: "/excel-export/export-prap-coupon",
  EXPORT_EVENT_PRODUCT: "/excel-export/export-draw-event",
  EXPORT_EVENT_SUBSCRIBER: "/excel-export/export-draw-event-order",
  EXPORT_TAX_COLLECTION: "/excel-export/export-collect-user-data-gp",
  EXPORT_VOTE_HOLD: "/excel-export/export-gp-list-vote-hold",

  DOWNLOAD_QNA: "/upload/download-file-qna",

  //Events
  //Prap
  GET_LIST_PRAP: "/pce/get-all-coupon",

  //Push Notification

  SEND_PUSH: "/fcm/push-to-all",
  GET_PUSH_HISTORY: "/fcm/list-sended",

  //Event Product
  GET_ALL_EVENT_PRODUCT: "/draw-event/find-by-admin",
  GET_ALL_EVENT_PRODUCT_FILTER: "/draw-event/find",
  CREATE_EVENT_PRODUCT: "/draw-event/create-event",
  UPDATE_EVENT_PRODUCT: "/draw-event/update-event",

  //Event Subcriber
  GET_ALL_EVENT_SUBCRIBER: "/draw-event/user-order/find-by-admin",
  UPDATE_EVENT_SUBCRIBER: "/draw-event/user-order/update-basic",
  CHANGE_SUBCRIBER_STATUS: "/draw-event/user-order/change-status",
};

export default Endpoints;
